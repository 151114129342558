@font-face {
  font-family: 'Neue Machina';
  src: url('../fonts/NeueMachina-Ultrabold.woff2') format('woff2'),
  url('../fonts/NeueMachina-Ultrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../fonts/NeueMachina-Light.woff2') format('woff2'),
  url('../fonts/NeueMachina-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Machina';
  src: url('../fonts/NeueMachina-Regular.woff2') format('woff2'),
  url('../fonts/NeueMachina-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url('../fonts/Inter.woff') format('woff'),
  url('../fonts/Inter.woff2') format('woff2'),
  url('../fonts/Inter.svg#Inter') format('svg'),
  url('../fonts/Inter.eot'),
  url('../fonts/Inter.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter.otf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
